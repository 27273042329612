import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import styled from "styled-components"
import { FaYoutube } from "react-icons/fa"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {
  Container,
  Heading,
  Subheading,
  Text,
} from "../../components/styled/Styled"
import { MEDIA_QUERIES, COLORS } from "../../constants"

const CVItem = styled.p`
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: ${COLORS.PRIMARY};
`

const ImgContainer = styled(motion.div)`
  position: relative;
  width: 40%;
  padding-top: 2rem;
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    width: 90%;
    margin: auto;
    padding-top: 2rem;
  }
`

const FullWidthImageContainer = styled(ImgContainer)`
  width: 100%;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    width: 100%;
  }
`

const Section = styled(motion.div)`
  display: flex;
  border-right: 8px solid ${COLORS.BLACK};
  flex-wrap: wrap;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    flex-direction: column-reverse;
    border-right: none;
    margin: auto;
  }
`

const TeamSection = styled(Section)`
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    &:nth-child(odd) {
      flex-direction: column;
    }
  }
`

const Article = styled(motion.div)`
  display: flex;
  width: 60%;
  flex-direction: column;
  padding: 0 3rem;
  text-align: right;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    flex-direction: column;
    width: 100%;
    margin: auto;
    padding: 0 1rem;
    text-align: center;
  }
`

const CV = styled(Article)`
  text-align: left;
  div {
    max-height: 550px;
    overflow-y: scroll;
  }

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    div {
      max-height: 2000px;
    }
  }
`
const StyledSubheading = styled(motion.div)`
  margin-right: 1rem;
  text-align: right;
  width: 100%;
  color: ${COLORS.PRIMARY};
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    display: none;
  }
`

const YoutubeLink = styled(motion.a)`
  position: absolute;
  display: block;
  color: ${COLORS.RED};
  top: 2.5rem;
  left: 1rem;
  cursor: pointer;
  z-index: 3;
`

const headingVar = {
  initial: { opacity: 0, x: "-300px" },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 2,
      delay: 1,
      type: "spring",
      stiffness: 40,
    },
  },
}

const textVar = {
  initial: { opacity: 0, x: "-300px" },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 2,
      delay: 1.2,
      type: "spring",
      stiffness: 40,
    },
  },
}

const imgVar = {
  initial: { opacity: 0, x: "-300px" },
  animate: {
    opacity: 1,
    x: 0,
    transition: { duration: 2, type: "spring", stiffness: 40 },
  },
}

const articleVar = {
  initial: { opacity: 0, y: "-300px" },
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 2, type: "spring", stiffness: 40 },
  },
}

const ChoreographerPage = () => {
  const {
    profile,
    landscape,
    empire,
    cv,
    collision,
    fannyPack,
    rebounce,
    company,
    pow,
  } = useStaticQuery(graphql`
    query {
      profile: file(relativePath: { eq: "anke-profile-4.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cv: file(relativePath: { eq: "anke-profile-1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pow: file(relativePath: { eq: "pow.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      empire: file(relativePath: { eq: "empire-crew-2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      collision: file(relativePath: { eq: "collision.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      company: file(relativePath: { eq: "company.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      rebounce: file(relativePath: { eq: "rebounce.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fannyPack: file(relativePath: { eq: "fanny-pack.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      landscape: file(relativePath: { eq: "anke-profile-2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Choreographer" />

      <Container>
        <Section>
          <StyledSubheading>CHOREOGRAPHER</StyledSubheading>
          <Article initial="initial" animate="animate" variants={articleVar}>
            <Heading initial="initial" animate="animate" variants={headingVar}>
              About me
            </Heading>
            <Text initial="initial" animate="animate" variants={textVar}>
              Anke is an independent professional dancer, who has 10 years of
              experience in coaching teams. She herself became Belgian Champion
              several times with Noaid and became Belgian, European and World
              Champion with Empire in 2016. She continued her wins in her own
              teams such as, Rebounce (winner Hiphop International and
              participation World Cup in Phoenix this year) and Collusion (3rd
              place European Championship this year). With Empire she got a
              GOLDEN BUZZER at Belgium's Got Talent in 2016, and this year you
              could see her team Rebounce go through to the semifinals of the
              same show. At the end of October she became World Champion with
              her partner Puya in the "duo adults" category of Hiphop Unite.
            </Text>
            <Text initial="initial" animate="animate" variants={textVar}>
              In 2020 Anke travelled internationally with her show ‘Devil’s Gun’
              with her collective Pair Of Waacks.
            </Text>
            <FullWidthImageContainer style={{ marginTop: "auto" }}>
              <Img fluid={landscape.childImageSharp.fluid} />
            </FullWidthImageContainer>
          </Article>
          <ImgContainer initial="initial" animate="animate" variants={imgVar}>
            <Img fluid={profile.childImageSharp.fluid} />
          </ImgContainer>
        </Section>
        <TeamSection style={{ paddingTop: "2rem" }}>
          <ImgContainer initial="initial" animate="animate" variants={imgVar}>
            <Img fluid={rebounce.childImageSharp.fluid} />
            <YoutubeLink
              whileHover={{ scale: 1.2 }}
              target="_blank"
              href="https://www.youtube.com/watch?v=drup1JJgWo0"
            >
              <FaYoutube style={{ height: "2rem", width: "2rem" }} />
            </YoutubeLink>
          </ImgContainer>
          <Article>
            <Heading initial="initial" animate="animate" variants={headingVar}>
              COACH REBOUNCE CREW
            </Heading>
            <Text initial="initial" animate="animate" variants={textVar}>
              Rebounce crew is more than just a dance crew. We are a family of
              dancers who support each other not only in the danceworld, but
              also in life . Anke started this crew in 2008 in her family’s
              dance-gym club GvGent. Back in the day, the crew consisted of 10
              members who participated annual shows and regional contests. Every
              year the crew evolved and in 2020 Anke started a collaboration
              with Julie Van den Brande. It was clear they make a golden duo and
              they started attracting more high level dancers from different
              cities in Belgium. Now the crew consist of high level dancers with
              expertise in different styles. Respect for all styles and their
              history and foundations are essential in this crew. The last few
              years Rebounce won several competitions & made it to the top 3 of
              high level (inter)national choreo competitions such as WOD, HHI,
              HHU, …
            </Text>
          </Article>
        </TeamSection>
        <TeamSection style={{ paddingTop: "2rem" }}>
          <Article>
            <Heading initial="initial" animate="animate" variants={headingVar}>
              COACH COLLUSION CREW
            </Heading>
            <Text initial="initial" animate="animate" variants={textVar}>
              Collusion crew is a girls-only crew and is a part of dance studio
              En Avant that is situated in Bonheiden. Femininity, strength, and
              a strong knowledge and understanding of the used styles are very
              important in this competition crew. We started as a ‘small crew’
              of dancers who didn’t have much experience in the choreo
              competition scene yet, but the crew evolved super quickly in a
              (inter)national competing crew with dancers from Ghent/Antwerp &
              Bonheiden. The crew peaked in 2019 as they became 3th at the
              European champs and 6th at the World Champs of HHU. In 2020 we
              expanded our crew with more powerwomen. In 2021 we will also
              compete in more show-based competitions.
            </Text>
          </Article>
          <ImgContainer initial="initial" animate="animate" variants={imgVar}>
            <Img fluid={collision.childImageSharp.fluid} />
            <YoutubeLink
              whileHover={{ scale: 1.2 }}
              target="_blank"
              href="https://youtu.be/aS0xrB71q-c"
            >
              <FaYoutube style={{ height: "2rem", width: "2rem" }} />
            </YoutubeLink>
          </ImgContainer>
        </TeamSection>
        <TeamSection style={{ paddingTop: "2rem" }}>
          <ImgContainer initial="initial" animate="animate" variants={imgVar}>
            <Img fluid={empire.childImageSharp.fluid} />
            <YoutubeLink
              whileHover={{ scale: 1.2 }}
              target="_blank"
              href="https://www.youtube.com/watch?v=aAc1RsX3U98"
            >
              <FaYoutube style={{ height: "2rem", width: "2rem" }} />
            </YoutubeLink>
          </ImgContainer>
          <Article>
            <Heading initial="initial" animate="animate" variants={headingVar}>
              Ex- Member & Co-choreographer EMPIRE CREW
            </Heading>
            <Text initial="initial" animate="animate" variants={textVar}>
              Each individual has his or her strength that contributes to the
              collective power of the group. The members of the crew are located
              all over Belgium (Ghent / Brussels / Geel / Antwerp / ...) Empire
              stands for positivity and friendship, for learning from each other
              and not taking everything too seriously. We are eager to grow as
              dancers and we will never be afraid to take on a new challenge.
              Empire became Belgian, European and World Champion HHU in 2016. We
              also made it to the semi-finals of Belgiums Got Talent.
            </Text>
          </Article>
        </TeamSection>
        <TeamSection style={{ paddingTop: "2rem" }}>
          <Article>
            <Heading initial="initial" animate="animate" variants={headingVar}>
              Founder / choreographer Pair Of Waacks (POW)
            </Heading>
            <Text initial="initial" animate="animate" variants={textVar}>
              Pair of Waacks is a waacking collective that was founded by Anke.
              The collective consist of dancers from different cities with
              different dancing backgrounds from hiphop, popping, dancehall,
              modern, vogue to waacking and locking. The collective brings
              waacking projects to the stage and was the first to represent the
              style with a ‘waacking-only show’ on the World Of Dance stage in
              2020, where they became 6th and won the ‘best concept’-award. The
              collective organised a ‘funkstyles’ online competition in the
              COVID-19 lockdown and we are planning to repeat this in real life
              in 2021.
            </Text>
          </Article>
          <ImgContainer initial="initial" animate="animate" variants={imgVar}>
            <Img fluid={pow.childImageSharp.fluid} />
            <YoutubeLink
              whileHover={{ scale: 1.2 }}
              target="_blank"
              href="https://www.youtube.com/watch?v=qRGaiIHTWco"
            >
              <FaYoutube style={{ height: "2rem", width: "2rem" }} />
            </YoutubeLink>
          </ImgContainer>
        </TeamSection>
        <TeamSection style={{ paddingTop: "2rem" }}>
          <ImgContainer initial="initial" animate="animate" variants={imgVar}>
            <Img fluid={company.childImageSharp.fluid} />
          </ImgContainer>
          <Article>
            <Heading initial="initial" animate="animate" variants={headingVar}>
              Coach Company by KC Dance Complex
            </Heading>
            <Text initial="initial" animate="animate" variants={textVar}>
              Recently in 2019, I became the coach of a junior competition crew
              in KC Dance Complex (Ghent). Our members have the age between
              12-18 years old. 2020 would have become our debut year if it
              wasn’t for the unfortunate outbreak of COVID-19. We focus a lot of
              our time on learning the correct basics of different styles within
              the urban-/street-/club- dance styles. Knowledge and skills go
              hand in hand. Hopefully we can start building our name in 2021.
            </Text>
          </Article>
        </TeamSection>
        <TeamSection style={{ paddingTop: "2rem" }}>
          <Article>
            <Heading initial="initial" animate="animate" variants={headingVar}>
              Coach Fanny Pack by DSA
            </Heading>
            <Text initial="initial" animate="animate" variants={textVar}>
              In 2016, I started teaching adults. The motivation and dedication
              of these woman were so high that we decided to start an adult
              competition crew. In 2019 we won the Belgian Champs of HHU in the
              senior division and we decided to go international with a duo that
              became 2nd on the World Champs and 3th on the European Champs of
              HHU 2019. We are always looking for strong women/mothers with a
              healthy dose of motivation to join our crew.
            </Text>
          </Article>
          <ImgContainer initial="initial" animate="animate" variants={imgVar}>
            <Img fluid={fannyPack.childImageSharp.fluid} />
            <YoutubeLink
              whileHover={{ scale: 1.2 }}
              target="_blank"
              href="https://www.youtube.com/watch?v=8p7qN1hvWIQ"
            >
              <FaYoutube style={{ height: "2rem", width: "2rem" }} />
            </YoutubeLink>
          </ImgContainer>
        </TeamSection>
        <Section style={{ paddingTop: "2rem" }}>
          <ImgContainer initial="initial" animate="animate" variants={imgVar}>
            <Img fluid={cv.childImageSharp.fluid} />
          </ImgContainer>
          <CV>
            <Heading initial="initial" animate="animate" variants={headingVar}>
              Curriculum Vitae
            </Heading>
            <div>
              <Subheading>2020</Subheading>
              <CVItem>Pair of Waacks</CVItem>
              <Text>
                - Guest performance with show ‘Devil’s gun’ at ‘The Bleeding
                hearts club’ in Denmark.
              </Text>
              <Text>
                - 3th place + ‘Best costume award’ at World Of Dance, Antwerp
                with selection to go to the World finals in LA.
              </Text>
              <CVItem>Rebounce crew</CVItem>
              <Text>
                - 3th place + ‘Best costume award’ at World Of Dance, Antwerp
                with selection to go to the World finals in LA.{" "}
              </Text>
              <Subheading>2019</Subheading>
              <CVItem>Funk it up - DUO</CVItem>
              <Text>
                - World champion DUO at Hiphop Unite, adults ( the Netherlands)
              </Text>
              <CVItem>Rebounce crew</CVItem>
              <Text>
                - 1st place: Hiphop International with selection to go to the
                World Finals in Phoenix (USA)
              </Text>
              <Text>- 1st place: MNM Urbanice Competition</Text>
              <Text>- 3th place: Hiphop Belgian Champs of UDO</Text>
              <Text>- 5th place: Belgian Champs of Hiphop Unite</Text>
              <Text>- Semi-finalist: TV show Belgium’s Got Talent</Text>
              <CVItem>Collusion crew</CVItem>
              <Text>
                - 3th place: European Champs of Hiphop Unite (the Netherlands)
              </Text>
              <Text>
                - 6th place: World Champs of Hiphop Unite (the Netherlands)
              </Text>
              <Text>- 3th place: at The Code</Text>
              <Text>- 5th place: at Legacy</Text>
              <CVItem>Fanny pack</CVItem>
              <Text>
                - 1st place: Belgian Champs of Hiphop Unite, seniors (the
                Netherlands)
              </Text>
              <Text>
                - 2nd place: DUO World Champs of Hiphop Unite, seniors (the
                Netherlands)
              </Text>
              <Text>
                - 3th place: DUO European Champs of Hiphop Unite, seniors (the
                Netherlands)
              </Text>
              <Subheading>2018</Subheading>
              <CVItem>Battle Droids</CVItem>
              <Text>
                - Assistant choreographer at TV show World Of Dance America
                season 3: working with coach Samuel Revell and his 'Battle
                Droids' breakdance crew (USA)
              </Text>
              <CVItem>Rebounce crew</CVItem>
              <Text>- 2nd place Belgian Champs of Hiphop Unite (Rebounce)</Text>
              <Text>
                - 5th place European Champs of Hiphop Unite (Czech Republic)
              </Text>
              <CVItem>Collusion crew</CVItem>
              <Text>- 5th place Belgian Champs of Hiphop Unite</Text>
              <Text>
                - 4th place: European Champs of Hiphop Unite (Czech Republic)
              </Text>
              <Subheading>2017</Subheading>
              <CVItem>Rebounce crew</CVItem>
              <Text>
                - Belgian champion/ Vice- World Champion in the over 18
                intermediate category UDO (UK){" "}
              </Text>
              <Subheading>2016</Subheading>
              <CVItem>Empire crew</CVItem>
              <Text>
                - Belgian/ European & World Champion at Hip hop Unite Megacrew
                Division (Czech Republic, Austria){" "}
              </Text>
              <Subheading>2008-2012</Subheading>
              <CVItem>NoAid/ TLC</CVItem>
              <Text>- 4 times Belgian Champion at Hip hop Unite</Text>
              <Text>
                - Vice- world champion Hiphop Unite with Noaid, juniors (Russia)
              </Text>
              <Text>- 4th place European Champs of Hiphop Unite (Spain)</Text>
              <Text>- 7th place World Champs of Hiphop Unite (Australia)</Text>
            </div>
          </CV>
        </Section>
      </Container>
    </Layout>
  )
}

export default ChoreographerPage
